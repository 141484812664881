import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import PageBuilder from '../components/PageBuilder'
import Footer from '../components/Footer/Footer'
import DrawerMenu from '../components/DrawerMenu'
import Cart from '../components/Cart'
import Header from '../components/Header'

const Page = (props) => {
  const {
    location,
    data: { wpPage },
  } = props
  const { title, uri, seo } = wpPage
  const { pageBuilder } = wpPage.pageBuilder
  return (
    <>
      <SEO
        description={seo.metaDesc}
        title={title}
        path={uri.substring(1)}
        image={seo.opengraphImage?.mediaItemUrl}
        imgalt={seo.opengraphImage?.altText}
      />
      <Header />
      <DrawerMenu />
      <Cart />
      {pageBuilder ? <PageBuilder data={pageBuilder} /> : null}
      <Footer />
    </>
  )
}

export default Page

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      uri
      ...Seo
      pageBuilder {
        pageBuilder {
          ... on WpPage_Pagebuilder_PageBuilder_Video {
            videoUrl
            fieldGroupName
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, maxHeight: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Pagebuilder_PageBuilder_BodyLarge {
            content
            fieldGroupName
          }
          ... on WpPage_Pagebuilder_PageBuilder_Hero {
            fieldGroupName
            image {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1280, maxHeight: 800, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ...WpAccordion
          ...WpBody
          ...WpLogoWall
          ...WpPageTitle
        }
      }
    }
  }
`
